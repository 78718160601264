/*
 * 字符串首字母转大写
 * 数据类型安全检测
 * 判断数据是否为null或undefined或空格字符串
 * 对象按需枚举
 */

/**
 * 字符串首字母转大写
 *
 * @param { String } str // 需要处理的字符串
 * @param { String } type // all, first
 * @return { String }
 */

export function switchStr(str, type = 'first') {
  if (type === 'first')
    return str.toString().trim().replace(str[0], str[0].toUpperCase())

  return str
    .toString()
    .trim()
    .toLowerCase()
    .replace(str[0], str[0].toUpperCase())
}

/**
 * 数据类型安全检测
 *
 * @param { any } data // 传入的检测数据，类型不定
 * @param { String } checkTypeStr // 进行检测的数据类型，字符串
 * @return { Boolean | String } // 返回布尔值, 如果没有指定检测类型，则返回数据类型
 */

export function checkDataType(data, checkTypeStr) {
  if (checkTypeStr) {
    const checkType = switchStr(checkTypeStr)
    return Object.prototype.toString.call(data) === `[object ${checkType}]`
  }

  return Object.prototype.toString.call(data)
}

/**
 * 判断数据是否为null或undefined或空格字符串
 *
 * @param { any } data // 检测的数据
 * @return { Boolean } 是返回true, 不是返回false
 */

export function checkEmpty(data) {
  if (checkDataType(data, 'null')) return true

  if (checkDataType(data, 'undefined')) return true

  if (checkDataType(data, 'string') && data.trim() === '') return true

  return false
}

/**
 * 对象按需枚举
 *
 * @param { Object } data // 需要枚举的数据对象
 * @param { Object } menu // 按需提取的控制对象
 * @param { Object } readDic // 读取的字典配置
 * @return { Object } // 返回枚举后的对象
 */

export function menuNeedObj(data, menu = {}, readDic = {}) {
  const obj = {}
  for (const key in menu)
    obj[key] = checkEmpty(deepReadObj(data, menu[key]))
      ? ''
      : deepReadObj(data, menu[key])

  for (const key in readDic) {
    if (Array.isArray(readDic[key])) {
      obj[key] = readDicFn(obj[key], readDic[key])
    }
  }

  return obj
}

/**
 * 读取字典
 *
 * @param { String | Number } value // 查询的数值
 * @param { Object [] } dic // 查询的字典
 * @return { String|Number } 返回查询的字典内容
 */

export function readDicFn(value, dic) {
  return dic.find((item) => item.value === value)?.label || value
}

/**
 * 字符串插入
 *
 * @param { String } str 被操作字符串
 * @param { Number } start 插入位置下标
 * @param { String } word 插入字符
 * @return { String } 返回新字符串
 */

export function insertStr(str, start, word) {
  return str.slice(0, start) + word + str.slice(start)
}

/**
 * 动态读取对象嵌套值
 *
 * @param { Object } state // 读取的嵌套对象
 * @param { String } path // 读取的链式嵌套连接
 *
 * @return { Any } // 返回读取值
 */

export function deepReadObj(state, path) {
  if (checkEmpty(path)) return null
  const pathList = path.split('.')
  return pathList.reduce((item, key) => {
    if (checkEmpty(item)) return null
    return item[key]
  }, state)
}

/**
 * 数字价格转换大写汉字
 *
 * @param { number } num // 价格
 * @return { Any } // 返回大写的金额
 */

export function convertToChinese(num) {
  const chineseDigits = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
  const chineseUnits = ['', '拾', '佰', '仟'];
  const highUnits = ['', '万', '亿', '兆'];

  // 处理整数部分
  let integerPart = Math.floor(num);
  let integerStr = integerPart.toString();
  let integerResult = '';
  for (let i = 0; i < integerStr.length; i++) {
    let digit = parseInt(integerStr[i]);
    let unitIndex = integerStr.length - 1 - i;
    let unit = chineseUnits[unitIndex % 4];
    if (digit !== 0) {
      integerResult += chineseDigits[digit] + unit;
      if (unitIndex % 4 === 0 && unitIndex > 0) {
        integerResult += highUnits[Math.floor(unitIndex / 4)];
      }
    } else {
      if (i < integerStr.length - 1 && parseInt(integerStr[i + 1]) !== 0) {
        integerResult += '零';
      }
    }
  }
  integerResult = integerResult.replace(/零+/g, '零').replace(/零$/, '');

  // 处理小数部分
  let decimalPart = num.toString().split('.')[1] || '';
  let decimalResult = '';
  for (let i = 0; i < decimalPart.length; i++) {
    let digit = parseInt(decimalPart[i]);
    if (i === 0 && digit !== 0) {
      decimalResult += chineseDigits[digit] + '角';
    } else if (i === 1 && digit !== 0) {
      decimalResult += chineseDigits[digit] + '分';
    }
  }
  if (decimalResult === '') {
    return integerResult + '元整';
  } else {
    return integerResult + '元' + decimalResult;
  }
}

/**
 * 更新餐段显示
 *
 * @param { Array } 餐段集合 
 * @return { Any } // 返回餐段是否多轮
 */
export function getOrderType(mealSegment, orderType, order_round) {
  let orderTypeName = mealSegment[orderType].name;
  if (order_round > 1) {
    orderTypeName = orderTypeName + convertNumberToChinese(order_round)
  }
  return orderTypeName
}
/**
 * @description: 数字转换为大写汉字
 * @return {*}
 * @param {*} num
 */
export const convertNumberToChinese = (num) => {
  const chineseNumbers = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
  return `${chineseNumbers[num]}轮`
}